<template>
  <div id="settings-panel" v-if="showSettingsWindow == true">
    <Permissions
    :showPremissionsModal="showPermissions"
    @closePremissionsModal="closePremissionsModal()"
    />    
    <div id="settings-window">
      <div id="settings-window-close">
          <a @click.prevent="closeSettingsWindow()"><img src="/img/btns/close_white.svg" width="40" height="40"/></a>
      </div>     
      <!--div id="settings-heading">
          <b-button pill variant="primary" @click="logout()">
              <div class="login-icon ps-icons"></div>
              {{$t('settings.signoutplayspaces')}}
          </b-button>        
      </div-->
      <b-row class="settings-content">
        <b-col>
          <Userdata/>
          <Parentalcontrols/> 
 
        </b-col>
        <b-col>

          <!--div id="help-group" class="settings-group">
            <h2>{{$t('settings.helpheading')}}</h2>
            <a href="mailto:support@playspaces.co?subject=Playspaces Beta Help" @click="trackEmail()" class="support-email" target="_blank">support@playspaces.co</a>
          </div-->      
          <Profiles
          @openPermissionsModal="openPermissionsModal()"
          />            
          <b-row>
            <b-col>
              <div id="links-group">
                <ul>
                  <li><a href="https://www.playspaces.co/our-story" @click="trackLink('about')" target="_blank">{{$t('settings.about')}} <div class="linkout-icon ps-icons-right"></div></a></li>
                  <li><a href="https://www.playspaces.co/faq" @click="trackLink('faq')" target="_blank">{{$t('settings.faq')}} <div class="linkout-icon ps-icons-right"></div></a></li>
                  <li><a href="https://www.playspaces.co/privacy-policy" @click="trackLink('privacy-policy')" target="_blank">{{$t('global.privacypolicy')}} <div class="linkout-icon ps-icons-right"></div></a></li>
                  <li><a href="https://www.playspaces.co/terms-of-use" @click="trackLink('terms')" target="_blank">{{$t('global.termsofservice')}} <div class="linkout-icon ps-icons-right"></div></a></li>
                  <!--li><a href="https://www.playspaces.co/tell-a-friend" @click="trackLink('tell-a-friend')" target="_blank">{{$t('settings.tellafriend')}} <div class="heart-icon ps-icons-right"></div></a></li-->
                </ul>
              </div>
            </b-col>
            <b-col>
              <div class="signout-button" @click="logout()">
                  {{$t('settings.signoutplayspaces')}}
              </div> 
              <!--div id="feedback-group">
                <img src="/img/elements/settings/feedback_alien.svg" width="172" height="130" />
                <p>{{$t('settings.feedbackheading')}}</p>
                <b-button pill variant="primary" @click="openFeedback()">
                  <div class="feedback-icon ps-icons"></div>
                  {{$t('global.feedback')}}
                </b-button>
              </div-->
            </b-col>
          </b-row>
        </b-col>
      </b-row> 
      <div id="settings-friends"><img src="/img/elements/settings/settings_friends.svg" width="295" height="130" /></div>
      <div id="footer">&copy; {{$t('global.copyright')}} | {{$t('settings.version')}} {{versionNumber}} <!--(BUILD #) -->| {{enivronmentName}}</div>
    </div>
  </div>  
</template>

<script>
//TODO add automated build number - sentry has something that can do that.
import { mapState } from 'vuex'
import Parentalcontrols from '@/components/settings/parentalcontrols.vue'
import Profiles from '@/components/settings/profiles.vue'
import Userdata from '@/components/settings/userdata.vue'
import Permissions from '@/components/modals/permissions.vue'

export default {
  name: "Settings",
  components: {
    Parentalcontrols,
    Profiles,
    Userdata,
    Permissions
  },
  watch: {
      'showSettingsWindow': {
          handler (newVal) {
              if (newVal === true) { 
                this.tracking({
                    type: 'page',
                    title: '/settings',
                });                   
              }
          },
          immediate: true 
      }
  }, 
  props:{
      showSettingsWindow: Boolean
  },   
  computed: {
    ...mapState(['activeProfile']),  
    ...mapState(['profiles']),       
    ...mapState(['userData'])
  },  
  data () {
    return {
      showPermissions: false,
      showUserData: true,
      showEditUserdata: false,
      enivronmentName: this.$store.getters.currentEnvironment,
      versionNumber: this.$store.getters.appVersion,
      parentalPin: null,
      profileCount: 0,
      emptyspaces: 6
    }
  },    
  mounted (){

  },
  methods: {
    openFeedback(){
      this.tracking({
          type: 'event',
          category: 'settings',
          action: 'feedback',
      });       
      let feedbackData = {
          show: true,
          playerName: "",
          sessionType: "",
          sessionRole: ""
      };
      this.$store.commit('setFeedback', feedbackData); 
    },
    closePremissionsModal(){
      this.showPermissions = false;
    },
    openPermissionsModal(){
      this.showPermissions = true;
    },
    closeSettingsWindow() {
      this.profileList = this.$store.getters.profileList;
      let updateActive = this.profileList.find(p => p.id === this.activeProfile.id);
      this.switchProfile(updateActive); 
      this.$emit('closeSettingsWindow');
    },
    logout(){
      this.$router.push('/logout');
      this.tracking({
          type: 'event',
          category: 'settings',
          action: 'logout'
      });  
    },
    trackLink(name){
      this.tracking({
          type: 'event',
          category: 'settings',
          action: 'external_link',
          label: name
      }); 
    },
    trackEmail(){
      this.tracking({
          type: 'event',
          category: 'settings',
          action: 'email_playspaces'
      });       
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';
#settings-panel {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5000;
  .signout-button {
    border: 3px solid $ps-purple;
    background: $ps-purple;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 140px;
    height: 140px;
    border-radius: 50%;
    font-weight: $ps-heavier-weight;
    font-size: $btn-font-size;
    line-height: $btn-font-lh;
    text-align: center;
    padding: 45px 0 0 0;
    color: $ps-white; 
    cursor: pointer;   
  }
  .signout-button:hover {
    border: 3px solid $ps-purple;
    background: $ps-light-purple;
    color: $ps-purple; 
    cursor: pointer;   
  }  
  .profile-cancel {
      margin: 0 0 0 20px;
  }
  #settings-window {
    width: 100%;
    height: 100%;
    background: #5328B8;
    padding: 0 30px;
    font-size: 11px;
    color: $ps-white;
    #settings-heading {
      color: $ps-white;
      padding: 30px 0;
      width: 200px;
    }
    .settings-content {
      padding: 60px 0 0 0;
    }
    .spacing-left {
      padding-left: 10px;
    }
    .settings-field-label {
        display: inline-block ;
        width: 80px;
        height: 30px;
        padding: 8px;
        margin: 0 0 18px 0;
    }
    .settings-input-field {
        display: inline-block ; 
        width: 100px;  
    }  
    .settings-input-field-long {
        display: inline-block ;   
        width: 280px;
        margin: 0 0 0 0;
    }   
    .settings-data-label {
      display: inline-block;
      width: 90px;
      font-weight: 600;
    }
    .settings-data-block {
      display: inline-block;
      border-bottom: #D8D4FC solid 1px;
      padding: 8px;
      width: 100px;
    }
    .settings-data-block-long {
      display: inline-block;
      border-bottom: #D8D4FC solid 1px;
      font-weight: 400;
      font-size: 0.688rem;
      height: 30px;
      margin: 0 0 25px 0 ;
      padding: 10px 0 0 16px;
      width: 280px;
      overflow: hidden;
    }  
    .settings-heading {
      margin: 30px;
    }  
    #footer {
      position: absolute;
      right: 30px;
      bottom: 30px;
      font-size: $body-copy-size;
      font-weight: lighter;
    }
    #settings-friends {
      position: absolute;
      margin: 0 auto;
      left: 370px;
      bottom: -35px;    
      z-index: 5100;
    }
  }
  #settings-window-close {
    position: absolute;
    top: 15px;
    right: 30px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 5200;
  }
  .settings-group, .settings-group-purple {
    position: relative;
    z-index: 5300;
    color: $ps-black;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    padding: 20px 30px 20px 30px;
    margin: 0 0 25px 0;
    font-size: 10px;
    .settings-edit-btn {
      position: absolute;
      top: 20px;
      right: 30px;
      a {
        text-decoration: none;
        margin: 0 0 0 20px;
      }
    }
  }
  .settings-group {
    background: $ps-white;
  }
  .settings-group-purple {
    background: $ps-light-purple;
  }
  #help-group {
    font-size: $h3-size;
    a {
      font-size: $h3-size;   
      line-height: $h3-lh;
      text-decoration: none;
    }
  }
  #links-group{
    ul {
      list-style-type: none; 
      padding: 0;
      margin: 0 0 0 30px;
    }
    li {
      width: 200px;
      margin: 0 0 20px 0;
      vertical-align: baseline;
    }
    a {
      font-size: $h3-size;
      color: $ps-white;
      font-weight: 400;
      text-decoration: none;
    }
  }
  #feedback-group {
    width: 221px;
    height: 247px;
    color: $ps-white;
    background: #330F87;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    padding: 15px;
    margin: 0 0 0 30px;
    text-align: center;
    p {
      color: $ps-white;    
    }
  }



}

</style>