<template>
<div id="arrow-modal-overlay"  v-if="showModal == true">
    <div id="arrow-modal-container" >
        <div id="confirm-delete-modal" class="ps-modal-box">
            <div>
                <h2>{{$t('settings.confirmdelete')}}</h2>
                <div v-if="error" class="error-msg">{{error}}</div>
                <br/>
                <p>{{$t('settings.confirmdeletedesc')}}</p>
                <br/><br/>
                <div>
                    <b-button pill variant="primary" @click="confirmDelete()" >
                        <div class="confirm-icon ps-icons d-inline-block"></div>
                        {{$t('global.confirm')}}
                    </b-button>
                    <div class="profile-cancel link-text d-inline-block" @click="closeModal()">
                        {{$t('global.cancel')}}
                    </div>
                </div>
            </div>  
        </div>         
    </div>
</div>    
</template>
<script>

export default {
  name: "Reauth", 
  props: {
    showModal: Boolean
  },
  data() {
    return {
        error: null,
        reauthpassword: null
    };
  },
  methods: {
    confirmDelete(){
      this.$emit('confirmDeleteProfile');        
    },        
    closeModal(){
      this.$emit('closeConfirmDelete');        
    }     
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';
#arrow-modal-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1024px;
    height: 768px;
    z-index: 5500;    
}
#arrow-modal-container {
    position: absolute;
    top: -9px;
    left: 34px;
    z-index: 5600;   
}
#arrow-modal-container::after {
    top: 10%;
    right: -36px;
    border: solid transparent;
    transform: rotate(-90deg);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: $ps-light-purple;
    border-width: 18px;
    margin-left: -36px;
}
#arrow-modal-container::before {
    content: '';
    top: 10%;
    right: -12px;  
    position: absolute;
    transform: rotate(-45deg);
    width: 36px;
    height: 36px;
    bottom: -12px;
    z-index: -1;
    margin-left: -11px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}
#confirm-delete-modal {
    background: $ps-light-purple;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    max-width: 360px;
    height: 100%;
    padding: 40px 60px;
    color: $ps-black;
}
</style>