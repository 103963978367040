<template>
<div id="settings-reauth-modal-overlay"  v-if="showReauthModal == true"> <!-- @click="closeSettingsReauthModal()" -->
    <div id="settings-reauth-modal-container" >
        <div id="settings-reauth-modal" class="ps-modal-box">
            <div>
                <h2>{{$t('settings.reauthheading')}}</h2>
                 <div v-if="error" class="error-msg">{{error}}</div>
                <br/>
                <form>
                    <input
                    v-model.trim="reauthpassword"
                    maxlength=50
                    id="password"
                    name="password"
                    type="password"
                    class="form-control"
                    required
                    />   
                    <br/>
                    <div class="link-text" @click="forgotPassword()">{{$t('auth.login.forgotpassword')}}</div>
                    <br/><br/><br/>
                    <div>
                        <b-button pill variant="primary" @click="validateFrom()"><div class="confirm-icon ps-icons d-inline-block"></div>{{$t('global.confirm')}}</b-button>
                        <div class="profile-cancel link-text d-inline-block" @click="closeSettingsReauthModal()">{{$t('global.cancel')}}</div>
                    </div>
                </form>
            </div>  
        </div>         
    </div>
</div>    
</template>
<script>

export default {
  name: "Reauth", 
  props: {
    showReauthModal: Boolean
  },
  data() {
    return {
        error: null,
        reauthpassword: null
    };
  },
  methods: {
    validateFrom(){
        if(!this.reauthpassword){
            this.error = this.$t('errors.passwordrequired');
            return;
        }
        this.reAuth();
        this.tracking({
            type: 'event',
            category: 'settings',
            action: 'manage_account:confirmpassword',
        });        
    },
    reAuth() {
        this.$emit('reauthPassword',this.reauthpassword);  
    },
    forgotPassword() {
        this.$router.push('/forgot');
        this.tracking({
            type: 'event',
            category: 'settings',
            action: 'manage_account:forgot',
        });  
    },
    closeSettingsReauthModal(){
      this.$emit('closeSettingsReauthModal');        
    }     
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';
#settings-reauth-modal-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1024px;
    height: 768px;
    z-index: 5500;    
}
#settings-reauth-modal-container {
    position: absolute;
    top: 55px;
    left: 185px;
    z-index: 5600;   
}
#settings-reauth-modal::after {
    bottom: 100%;
    left: 0px;
    border: solid transparent;
    transform: rotate(180deg);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: $ps-light-purple;
    border-width: 18px;
    margin-left: 50%;
}
#settings-reauth-modal::before {
    content: '';
    bottom: 100%;
    left: 0px;
    margin-left: 50%;
    margin-bottom: -27px;
    position: absolute;
    transform: rotate(135deg);
    width: 36px;
    height: 36px;
    z-index: -1;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
#settings-reauth-modal {
    background: $ps-light-purple;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    width: 340px;
    height: 100%;
    padding: 30px;
    color: $ps-black;
}
</style>