<template>
    <div id="feedback-modal">
        <Smallmodal
        :modalShow="showFeedback"
        @closeSmallModal="closeModal()"
        >
        <div id="feedback-modal-content">
            <div v-if="showPlayspaceMsg == true">
                <h2>{{$t('feedback.sessionend1')}}{{playerName}}{{$t('feedback.sessionend2')}}</h2>
            </div>
            <div id="feedback-friends">
            <img src="/img/elements/home/feedback_friends.svg" width="365" height="190"/>
            </div>
            <h2>{{$t('feedback.heading')}}</h2>
            <div v-if="error" class="error-msg">{{error}}</div>
            <br/>
            <b-row>
            <b-col>
                <div class="circle-btn-cancel" @click="negative()" v-if="loading.negative == false">
                    <img src="/img/btns/thumbs_down.svg" width="50" height="50" :alt="$t('global.no')" :title="$t('global.no')"/>
                </div>
                <div class="circle-btn-cancel" v-else>
                    <img src="/img/btns/loading.svg" width="50" height="50" :alt="$t('global.loading')" :title="$t('global.loading')"/>
                </div>                
            </b-col>
            <b-col>
                <div class="circle-btn-done" @click="positive()" v-if="loading.positive == false">
                    <img src="/img/btns/thumbs_up.svg" width="50" height="50" :alt="$t('global.yes')" :title="$t('global.yes')"/>
                </div>
                <div class="circle-btn-done" v-else>
                    <img src="/img/btns/loading_white.svg" width="50" height="50" :alt="$t('global.loading')" :title="$t('global.loading')"/>
                </div>                
            </b-col>
            </b-row> 
            <br/>
            <br/>  
        </div>
        </Smallmodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'
export default {
    name: "Feedback", 
    components: {
        Smallmodal
    },
    props:{
        showFeedback: Boolean
    },
    watch: {
        'showPlayerleft': {
            handler (newVal) {
                if (newVal && this.showFeedback == true) { 
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'popup:view',
                        label: 'feedback'
                    });
                    this.loading.negative = false;  
                    this.loading.positive = false;     
                }
            },
            immediate: true 
        }
    },          
    computed: {
        ...mapState(['userData']),
        ...mapState(['feedback']),  
    },
    data: function() {
        return {
            error: null,
            showPlayspaceMsg: false,
            playerName: null,
            loading: {
                positive: false,
                negative: false
            }
        }   
    },
    methods: {
        loadFeedback(){

        },
        async closeModal() {
            
            this.$emit('closeFeedback');
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:close',
                label: 'feedback'
            });   
            await this.$store.commit('setFeedback', null);   
            this.loading.negative = false;  
            this.loading.positive = false;     
        },
        async positive(){
            this.loading.positive = true;
            this.tracking({
                type: 'event',
                category: 'feedback',
                action: 'fungame:yes'
            });             
            await this.$store.dispatch('userFeedback', "Yes");
            this.closeModal();
        },
        async negative(){
            this.loading.negative = true;
            this.tracking({
                type: 'event',
                category: 'feedback',
                action: 'fungame:no'
            });             
            await this.$store.dispatch('userFeedback', "No");
            this.closeModal();
        }
    },
    mounted(){
        //this.$t('videochat.guestname');
        if(this.feedback?.playerName != this.$t('videochat.guestname') && this.feedback?.playerName != null){
            this.showPlayspaceMsg = true; 
            this.playerName = this.feedback.playerName || null;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

#feedback-modal-content {
    padding: 50px 0 0 0;
    #feedback-friends {
        margin: 0 0 0 -25px;

    }
}


</style>