<template>
<div>    
    <Confirmdelete
    :showModal="show.confirmdelete"
    @confirmDeleteProfile="confirmDeleteProfile()" 
    @closeConfirmDelete="closeConfirmDelete()"
    />
    <Avatars
    :avatarPhoto="profile.profilePhoto"
    :showAvatarsModal="showAvatarsModal"
    @changeAvatar="changeAvatarPic($event)" 
    @closeSettingsAvatarModal="closeAvatarMenu()"
    />
        <div id="profile-list" v-if="showProfileList == true">
            <div id="profile-group" class="settings-group">
                <h2>{{$t('settings.manageprofileheading')}}</h2>
                <br/>
                <b-row align-h="center" class="text-center">
                    <b-col v-for="profile in profileList" :key="profile.id" @click="openProfileDetails(profile)">
                        <div class="profile-item">
                        <div class="avatar-container avatar-md avatar" > <!-- @click="openProfileDetails(profile)"-->
                            <img 
                            class="avatar-img"
                            :src="profile.profilePhoto" 
                            width="80"
                            height="80"
                            />
                        </div> 
                        <div class="profile-name truncate">{{profile.name}}</div>
                        </div> 
                    </b-col>
                    <b-col v-for="n in emptyspaces" :key="n.id" @click="openAddProfile()">
                        <div class="profile-item">
                        <div class="avatar-container avatar-md avatar" > <!-- @click="openAddProfile()" -->
                            <img 
                            class="profile-add"
                            src="/img/btns/add_profile.svg" 
                            width="80"
                            height="80"                            
                            />
                        </div> 
                        <div class="profile-name truncate"></div>
                        </div> 
                    </b-col>
                </b-row>
            </div>  
        </div> 
        <div id="add-profile" v-if="showProfileDetail == true">
            <div id="profile-group" class="settings-group">
                <h2>{{$t('settings.profiledetailheading')}}</h2>
                <div v-if="error" class="error-msg">{{error}}</div>
                <br/>
                <div class="avatar-block d-inline-block">
                    <div class="avatar-container avatar-md avatar">
                        <img 
                        class="avatar-img"
                        :src="profile.profilePhoto"
                        width="80"
                        height="80"                        
                        />
                    </div> 
                    <div class="change-avatar link-text">&nbsp; </div>
                    <br/>
                </div>
                <div class="profile-data-block d-inline-block">              
                    <div class="settings-field-label">
                        <label>{{$t('signup.step3.firstname')}}</label>
                    </div>
                    <div class="settings-data-block">
                        {{profile.name}}
                    </div>
                    <br/>
                    <div class="settings-field-label">
                        <label>{{$t('signup.step3.birthday')}}</label>
                    </div>
                    <div class="settings-data-block">
                        {{profile.birthday.month}}/{{profile.birthday.day}}/{{profile.birthday.year}}
                    </div> 
                    <br/>
                    <div class="settings-field-label">
                        <label>{{$t('signup.step3.accesslevel')}}</label>
                    </div>
                    <div class="settings-data-block" v-if="profile.type != 'owner'">
                        {{profile.type}}
                    </div>
                    <div class="settings-data-block" v-else>
                        Master Profile
                    </div>                        
                    <br/>
                    <br/>                                              
                </div>
                <br/>
                <br/>    
                <b-button pill variant="primary" @click="openEditProfile(profile)">
                    <div class="edit-icon ps-icons"></div>
                    {{$t('global.edit')}}
                </b-button>
            </div>
        </div>
        <div id="add-profile" v-if="showAddProfile == true || showEditProfile == true ">
            <div id="profile-group" class="settings-group-purple">
                <h2 v-if="showAddProfile == true">{{$t('settings.profileaddheading')}}</h2>
                <h2 v-if="showEditProfile == true">{{$t('settings.profileeditheading')}}</h2>
                <div class="delete-profile link-text" @click="deleteProfile(profile.id)" v-if="showEditProfile == true && profile.type != 'owner'">{{$t('global.delete')}}</div>
                <div v-if="error" class="error-msg">{{error}}</div>
                <br/>
                <form>
                    <div class="avatar-block d-inline-block align-top">
                        <div @click="openAvatarMenu()" class="avatar-container avatar-md avatar">
                            <img 
                            class="avatar-img"
                            :src="profile.profilePhoto"
                            width="80"
                            height="80"                            
                            />
                        </div> 
                        <div class="change-avatar link-text" @click="openAvatarMenu()" >{{$t('global.change')}}</div>
                        <br/>
                    </div>
                    <div class="profile-data-block d-inline-block align-middle">        
                        <div class="profile-data-label d-inline-block">
                            <label>{{$t('signup.step3.firstname')}}</label>
                        </div>
                        <div class="d-inline-block align-top profile-settings-name">
                            <input
                            type="text"
                            class="form-control "
                            id="name"
                            name="name"
                            maxlength=30
                            v-model.trim="profile.name"
                            />
                        </div>
                        <br/>
                        <div class="profile-data-label d-inline-block align-middle">
                            <label>{{$t('signup.step3.birthday')}}</label>
                        </div>
                        <div class="d-inline-block align-top">
                            <input
                            v-model.trim="profile.birthday.month" 
                            class="form-control birthday-month d-inline-block"
                            type="text" 
                            maxlength=2
                            placeholder="MM"
                            id="bdaymonth" 
                            name="bdaymonth"
                            required
                            autofocus
                            tabindex="5"            
                            />
                            <input
                            v-model.trim="profile.birthday.day" 
                            class="form-control birthday-day d-inline-block"
                            type="text" 
                            maxlength=2
                            placeholder="DD"
                            id="bdayday" 
                            name="bdayday"
                            required
                            autofocus
                            tabindex="6"            
                            />            
                            <input
                            v-model.trim="profile.birthday.year" 
                            class="form-control birthday-year d-inline-block"
                            type="text" 
                            placeholder="YYYY"
                            maxlength=4
                            id="bdayyear" 
                            name="bdayyear"
                            required
                            autofocus
                            tabindex="7"            
                            /> 
                        </div>
                        <br/>
                        <div class="radio-group">
                        <div class="profile-data-label d-inline-block align-middle">
                            <div class="d-inline-block align-top"><label>{{$t('signup.step3.accesslevel')}}</label></div>
                            <div class="d-inline-block align-top">
                                <div @click="openPermissionsModal()" class="permissions-info pointer important-bubble">!</div>
                            </div>

                        </div>
                        <div v-if="profile.type != 'owner'" class="d-inline-block align-top">    
                            <div class="d-inline-block align-top"> 
                            <b-form-radio 
                            v-model.trim="profile.type"
                            id="adult"
                            name="type"
                            class="radio-input custom-radio-label"
                            type="radio"
                            value="adult"
                            tabindex="8"    
                            required 
                            size="lg"  
                            >
                            {{$t('signup.step3.adult')}}
                            </b-form-radio>                            
                            </div>
                            <div class="d-inline-block">                        
                            <b-form-radio 
                            v-model.trim="profile.type"
                            id="kid"
                            name="type"
                            class="radio-input custom-radio-label"
                            type="radio"
                            value="kid"
                            tabindex="9"  
                            required  
                            size="lg"
                            >
                            {{$t('signup.step3.kid')}}
                            </b-form-radio>
                            </div>
                        </div> 
                        <div v-else class="d-inline-block">
                            <b-form-radio 
                            v-model.trim="profile.type"
                            id="user"
                            name="type"
                            class="radio-input custom-radio-label"
                            type="radio"
                            value="owner"
                            tabindex="9"  
                            required   
                            size="lg"
                            disabled-field
                            >
                            {{$t('global.masterprofile')}}
                            </b-form-radio>                                                      
                        </div>
                        </div>
                        <br/><br/>
                    </div> 
                </form>
                <br/><br/>
                <b-button pill variant="primary" @click="validateForm('add')" v-if="showAddProfile == true">
                    <div class="save-icon ps-icons"></div>
                    {{$t('global.save')}}
                </b-button>
                <b-button pill variant="primary" @click="validateForm('edit')" v-if="showEditProfile == true">
                    <div class="save-icon ps-icons"></div>
                    {{$t('global.save')}}
                </b-button>
                <div class="profile-cancel link-text d-inline-block" @click="openProfileList()">{{$t('global.cancel')}}</div>
            </div>
        </div>                       
</div>    
</template>

<script>
import { mapState } from 'vuex'
import Avatars from '@/components/settings/miniavatars.vue'
import Confirmdelete from '@/components/settings/confirmdelete.vue';

export default {
    name: "Profiles",
    components: {
        Avatars,
        Confirmdelete
    },  
    computed: {
        ...mapState(['userData']),
        ...mapState(['profiles']),
        ...mapState(['activeProfile']),
    },
    watch: {
        'profiles': {
        handler (newVal) {
            if (newVal) { 
                this.profileList = this.$store.getters.profileList;
                this.emptyspaces = 6 - this.$store.getters.profileCount;
            }
        },
        immediate: true 
        },    
        profile: {
            handler: function(newVal) { // watch it
                if(this.profile.birthday.day != null && this.profile.birthday.month != null && this.profile.birthday.year != null) {
                    if(this.profile.type != 'owner') {
                        if(this.ageCheck(this.profile.birthday.year)){
                        this.profile.type = 'adult';
                        } else {
                        this.profile.type = 'kid';         
                        }
                    }

                }
            },
            deep: true
        }
    },    
    data () {
        return {
            showProfileList: true,
            showProfileDetail: false,
            showAddProfile: false, 
            showEditProfile: false,
            showAvatarsModal: false,
            show: {
                confirmdelete: false
            },
            delete: {
                profileId: null
            },
            profileList: this.$store.getters.profileList,
            emptyspaces: 6 - this.$store.getters.profileCount,
            profilename: null,
            fullbirthday: null,
            error: null,
            profile: {
                id: null,
                name: null,
                birthday: {
                    day: null,
                    month: null,
                    year: null
                },
                profilePhoto: "/img/avatars/unset_avatar.svg",
                type: null
            }
        }
    },
  methods: {
    openPermissionsModal(){
        this.$emit('openPermissionsModal');
        this.tracking({
            type: 'event',
            category: 'settings',
            action: 'infopopup'
        });            
    },
    resetForm(){
        this.profile.name = null;
        this.profile.birthday.year = null;
        this.profile.birthday.month = null;
        this.profile.birthday.day = null;
        this.profile.profilePhoto = "/img/avatars/unset_avatar.svg",
        this.profile.type = null;
        this.error = null;
        this.emptyspaces = 6 - this.$store.getters.profileCount;
    },
    openProfileDetails(profile){
        this.showProfileList = false;
        this.showAddProfile = false;
        this.showEditProfile = false;
        this.showProfileDetail = true;
        this.profile.id = profile.id;
        this.profile.name = profile.name;
        let dateparts = profile.birthday + '';
        dateparts = dateparts.split('-');
        let year = dateparts[0];
        let month = dateparts[1];
        let day = dateparts[2];
        this.profile.birthday.year = year;
        this.profile.birthday.month = month;
        this.profile.birthday.day = day;
        this.profile.profilePhoto = profile.profilePhoto;
        this.profile.type = profile.type;
        this.tracking({
            type: 'event',
            category: 'settings',
            action: 'manage_profile:view',
        });  
    },
    openProfileList(){
        //shows profile list
        this.resetForm();
        this.showProfileList = true;
        this.showEditProfile = false;
        this.showAddProfile = false;
        this.showProfileDetail = false;
    },
    openEditProfile(profile){
        //TODO refactor this as it's already handled in profile detail view.
        this.showProfileList = false;
        this.showAddProfile = false;
        this.showEditProfile = true;
        this.showProfileDetail = false;
        /*this.profile.id = profile.id;
        this.profile.name = profile.name;
        let dateparts = profile.birthday + '';
        dateparts = dateparts.split('-');
        let year = dateparts[0];
        let month = dateparts[1];
        let day = dateparts[2];
        this.profile.birthday.year = year;
        this.profile.birthday.month = month;
        this.profile.birthday.day = day;
        this.profile.profilePhoto = profile.profilePhoto;
        this.profile.type = profile.type; */
        this.tracking({
            type: 'event',
            category: 'settings',
            action: 'manage_profile:edit',
        });                   
    },
    openAddProfile(){
        this.resetForm();
        this.showProfileList = false;
        this.showEditProfile = false;
        this.showAddProfile = true;
        this.showProfileDetail = false;
        this.tracking({
            type: 'event',
            category: 'settings',
            action: 'manage_profile:add',
        });           
    },
    validateForm(actionType){
        if(actionType === 'add') {
            // TODO mirror this logic in sign up flow
            if(!this.profile.birthday.month || !this.profile.birthday.day || !this.profile.birthday.year){
                this.error = this.$t('errors.birthday');
                return;
            } else {
                this.fullbirthday = this.formatBirthday(this.profile.birthday.month,this.profile.birthday.day,this.profile.birthday.year);
                if(!this.validYear(this.profile.birthday.year)){
                    this.error = this.$t('errors.invalidbirthday');
                    this.saving = false;
                    return;        
                }     
                if(!this.validDate(this.fullbirthday)){
                    this.error = this.$t('errors.invalidbirthday');
                    return;         
                }                 
                if(this.profile.type == 'adult'){
                    if(!this.ageCheck(this.fullbirthday)){
                        this.error = this.$t('errors.adults18');
                        this.saving = false;
                        return;         
                    }                     
                }             
            }
            if(!this.profile.name){
                this.error = this.$t('errors.firstname');
                return;
            }
            if(!this.profile.profilePhoto || this.profile.profilePhoto == '/img/avatars/unset_avatar.svg'){
                this.error = this.$t('errors.nophoto');
                return;
            }

            if(!this.profile.type){
                this.error = this.$t('errors.typerequired');
                return;
            }     
            this.addProfile();
            this.tracking({
                type: 'event',
                category: 'settings',
                action: 'manage_profile:addsave',
            });                 
        }
        if(actionType === 'edit') {
            //TODO add edit validation
            //user type needs a different config

            if(!this.validYear(this.profile.birthday.year)){
                this.error = this.$t('errors.invalidbirthday');
                this.saving = false;
                return;        
            }
            if(!this.profile.birthday.month || !this.profile.birthday.day || !this.profile.birthday.year){
                this.error = this.$t('errors.birthday');
                return;
            } else {
                this.fullbirthday = this.formatBirthday(this.profile.birthday.month,this.profile.birthday.day,this.profile.birthday.year);
                if(this.profile.type == 'owner' || this.profile.type == 'adult'){
                    if(!this.ageCheck(this.fullbirthday)){
                        this.error = "Adults must be 18 or older.";
                        this.saving = false;
                        return;         
                    }                     
                }            
            }
            if(!this.profile.name){
                this.error = this.$t('errors.firstname');
                return;
            }
            if(!this.profile.profilePhoto){
                this.error = this.$t('errors.nophoto');
                return;
            }
            if(!this.validDate(this.fullbirthday)){
                this.error = this.$t('errors.invalidbirthday');
                return;         
            }
            if(this.profile.type != 'owner'){
                if(!this.profile.type){
                    this.error = this.$t('errors.typerequired');
                    return;
                }
            } else {
                this.profile.type = 'owner';    
            }
            this.updateProfile();   
            this.tracking({
                type: 'event',
                category: 'settings',
                action: 'manage_profile:editsave',
            });                  
        }
    },
    updateProfile(){
      this.$store.dispatch('updateProfile', {
        profileId: this.profile.id,
        name: this.profile.name,
        profilePhoto: this.profile.profilePhoto,
        birthday: this.fullbirthday,
        type: this.profile.type,
      })
      .catch(error => {
        this.error = error.message;
        this.logError(error);
      })
      this.openProfileList();
    },
    openAvatarMenu(){
        this.showAvatarsModal = true;
    },
    closeAvatarMenu(){
        this.showAvatarsModal = false;
    },    
    changeAvatarPic(path){
      this.profile.profilePhoto = path;
    },
    closeConfirmDelete(){
        this.show.confirmdelete = false;
    },
    openConfirmDelete(){
        this.show.confirmdelete = true;
    }, 
    confirmDeleteProfile(){
        this.$store.dispatch('deleteProfile', this.delete.profileId);
        // If you are deletign the current active profile switch to master profile.
        if(this.delete.profileId === this.activeProfile.id) {
            this.$store.commit('setActiveProfile', this.$store.getters.masterProfile); 
        }
        this.tracking({
            type: 'event',
            category: 'settings',
            action: 'manage_profile:delete',
        });  
        this.openProfileList();
        this.delete.profileId = null;
        this.closeConfirmDelete();
    },   
    addProfile() {
      this.$store.dispatch('addProfile', {
        name: this.profile.name,
        profilePhoto: this.profile.profilePhoto,
        birthday: this.fullbirthday,
        type: this.profile.type,
      })
      .catch(error => {
        this.error = error.message;
        this.logError(error);
      });
      this.emptyspaces = this.emptyspaces - 1;
      this.openProfileList();
    },
    deleteProfile(profileId){
        this.delete.profileId = profileId; 
        this.openConfirmDelete();
    }
  },
  mounted() {

    
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';

#profile-group {
    min-width: 320px;
    .change-avatar {
        margin: 10px 0 0 0;
        width: 100px;
        text-align: center;
    }
    .radio-group {
        margin: 10px 0 0 0;
    }
    .radio-input{
        vertical-align: top;
        label {
        padding: 7px 0 0 0;
        margin: 0 10px 0 0;
        }
    }
    .delete-profile {
        position: absolute;
        top: 30px;
        right: 30px;
    }
    .avatar-block {
        width: 125px;
        margin: 0 auto;
        text-align: center;
    }
    .profile-settings-name {
        width: 156px;
    }
    .profile-data-label {
        width: 95px;
        font-weight: 600;
        padding: 8px 0 0 0;
    }
    .profile-data-block {
        width: 275px;        
    }
    .profile-item {
        margin-right: 8px;
        margin-bottom: 10px;
    }  
    .profile-add {
        width: 40px;
        height: 40px;
        margin: 27px 0 0 0; 
    }  
    .profile-name {
        margin: 10px auto 0;
        text-align: center;
        width: 80px;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;    
    }  
    .truncate {
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }        
}
</style>