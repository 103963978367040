<template>
  <div id="home-screen">
    <Pwa
    :showPWA="show.pwa"
    @closePwaModal="closePwa()"
    />    
    <Feedback
    :showFeedback="show.feedback"
    @closeFeedback="closeFeedback()"
    />
    <Ceateinvite
    :showCreateInvite="showCreateInvite"
    @closeCreateInvite="closeCreateInviteFromHome()"       
    />
    <Settings
    :showSettingsWindow="showSettingsWindow"    
    @closeSettingsWindow="closeSettingsWindowFromHome()"     
    />
    <Nav
    @openCreateInvite="openCreateInviteFromHome()"
    @openSettingsWindow="openSettingsWindowFromHome()" 
    :openAgeGateHome="show.agegate"    
    @closeAgeGate="closeAgeGate()"   
    @pinAccepted="correctPIN()"
    />
    <div id="home-content-container">
      <div id="home-content-slider">
        <div id="home-content-drawer">
          <div id="home-content">
              <!--div id="home-avatar" class="d-inline-block align-top">
                <img :src="homeAvatar" />
              </div--> 
              <div class="tall-card d-inline-block align-top">
                <div class="tall-card-heading">
                  <h1>{{$t('home.playspacecard.heading')}}</h1>
                </div>
                <div>
                <b-button pill variant="primary" @click.prevent="scheduleCardStart()" v-if="show.scheduleCardStart == true">
                  <div class="join-icon ps-icons"></div>
                  {{$t('global.letsgo')}}
                </b-button>
                <b-button pill variant="secondary" v-else>
                  <div class="loading-icon ps-icons"></div>
                  {{$t('global.loading')}}
                </b-button>    

                <img class="launch" src="/img/elements/home/launch_playspace.svg" width="241" height="328"/>
                </div>
              </div>
              <div class="d-inline-block align-top">
                <div class="home-row">
                  <div class="lg-card d-inline-block align-top">          
                    <img class="card-img" src="/img/cards/drawing_card.svg" width="360" height="260"/>
                    <div class="card-btn-holder">
                      <b-button pill variant="primary" @click.prevent="openSpace('Drawing','create')" v-if="show.drawingApp == true">
                        <div class="create-icon ps-icons"></div>
                        {{$t('global.create')}}
                      </b-button>
                      <b-button pill variant="secondary" v-else>
                        <div class="loading-icon ps-icons"></div>
                        {{$t('global.loading')}}
                      </b-button>                         
                    </div>
                  </div>  
                  <div class="md-card gradient-card d-inline-block align-top"> 
                    <img :src="this.simpleMachinesCard.assetUrl" width="207" height="112"/>
                    <div class="card-copy">
                      <p>{{this.simpleMachinesCard.heading}}</p> 
                    </div>
                    <div class="card-btn-holder">
                      <b-button pill variant="primary" @click.prevent="smInspirationCard(simpleMachinesCard.name)" v-if="show.smInspirationCard == true">
                        <div class="play-icon ps-icons"></div>
                        {{$t('global.play')}}
                      </b-button>
                      <b-button pill variant="secondary" v-else>
                        <div class="loading-icon ps-icons"></div>
                        {{$t('global.loading')}}
                      </b-button>                        
                    </div>
                  </div>
                  <div class="lg-card">
                      <img class="card-img" src="/img/elements/play/saved_creation.svg" width="360" height="260"/>
                      <div class="card-btn-holder">
                          <b-button pill variant="primary" @click.prevent="savedCreationCard()" v-if="show.savedCreationCard == true">
                              <div class="edit-icon ps-icons"></div>
                              {{$t('global.edit')}}
                          </b-button>
                          <b-button pill variant="secondary" v-else>
                            <div class="loading-icon ps-icons"></div>
                            {{$t('global.loading')}}
                          </b-button>                              
                      </div>
                  </div>
                  <div class="md-card gradient-card d-inline-block align-top"> 
                    <img src="/img/elements/home/feedback_alien.svg" width="240" height="213"/>
                    <div class="card-btn-holder">
                      <a href="https://www.playspaces.co/feedback" target="_blank">
                        <b-button pill variant="primary" @click.prevent="openFeedback()">
                          <div class="feedback-icon ps-icons"></div>
                          {{$t('global.feedback')}}
                        </b-button>
                      </a>
                    </div>
                  </div>                  
                </div> 
                <div class="home-row">
                  <div class="md-card blue-card d-inline-block align-top"> 
                    <img src="/img/elements/home/invite_aliens.svg" width="207" height="105"/>
                    <div class="card-copy">
                      <p>{{$t('home.invitecard.subheading')}}</p> 
                    </div>
                    <div class="card-btn-holder">
                      <b-button pill variant="primary" @click.prevent="inviteCard()">
                        <div class="add-circle-icon ps-icons"></div>
                        {{$t('global.invite')}}
                      </b-button>
                    </div>
                  </div>   
                  <div class="lg-card d-inline-block align-top">
                    <img class="card-img" src="/img/cards/simple_machines_card.svg" width="360" height="260"/>
                    <div class="card-btn-holder">
                      <b-button pill variant="primary" @click.prevent="openSpace('SimpleMachines','build')" v-if="show.smipleMachine == true">
                        <div class="build-icon ps-icons"></div>
                        {{$t('global.build')}}
                      </b-button>
                      <b-button pill variant="secondary" v-else>
                        <div class="loading-icon ps-icons"></div>
                        {{$t('global.loading')}}
                      </b-button>                           
                    </div>
                  </div>                                  

                  <div class="md-card gradient-card d-inline-block align-top"> 
                    <img src="/img/elements/home/download_aliens.svg" width="208" height="106"/>
                    <div class="card-copy">
                      <br/>
                      <p>{{$t('home.pwacard.heading')}}</p>  
                    </div>
                    <div class="card-btn-holder">
                      <b-button pill variant="primary" @click.prevent="openPWA()">
                        <div class="download-icon ps-icons"></div>
                        {{$t('global.download')}}
                      </b-button>
                    </div>
                  </div>
                  <div class="lg-card blue-card d-inline-block align-top">    
                    <div class="daily-challenge">
                      <img src="/img/cards/daily_challenge.svg" width="18" height="212" />
                    </div>      
                    <img :src="this.drawingCard.assetUrl" height="132"/>
                    <div class="card-copy-drawing">
                    <p>{{this.drawingCard.heading}}</p>
                    </div> 
                    <div class="card-btn-holder">
                      <b-button pill variant="primary" @click.prevent="drawInspirationCard(drawingCard.name)" v-if="show.drawInspirationCard == true">
                        <div class="create-icon ps-icons"></div>
                        {{$t('global.create')}}
                      </b-button>
                      <b-button pill variant="secondary" v-else>
                        <div class="loading-icon ps-icons"></div>
                        {{$t('global.loading')}}
                      </b-button>                        
                    </div>
                  </div>                    
                </div>                     
              </div>  
          </div>                     
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/nav.vue'
import Ceateinvite from '@/components/createinvite.vue'
import Settings from '@/components/settings/index.vue'
import Feedback from '@/components/modals/feedback.vue'
import Pwa from '@/components/modals/pwa.vue'
import simpleMachinesCards from '@/data/en/sm-cards.json'
import drawingCards from '@/data/en/drawing/inspirations.json'
import Activities from '@/data/en/activities.json'

export default {
  name: "Home",
  components: {
    Nav,
    Ceateinvite,
    Settings,
    Feedback,
    Pwa
  },
  watch: {
      'activeProfile': {
        handler (newVal) {
            if (newVal) { 
                this.homeAvatar = this.activeProfile.profilePhoto;
                // refires home tag when profile changes.
                this.trackHomePage();
            }
        },
        immediate: true 
      },
      'feedback': {
        handler (newVal) {
            if (newVal) { 
                if(this.feedback.show){
                  this.show.feedback = true;
                } else {
                  this.show.feedback = false;                 
                }
            }
        },
        immediate: true 
      }      
  },   
  data() {
    return {
      show: {
        feedback: false,
        schedule: false,
        invite: false,
        settings: false,
        pwa: false,
        savedCreationCard: true,
        smInspirationCard: true,
        smipleMachine: true,
        scheduleCardStart: true,
        drawingApp: true,
        drawInspirationCard: true,
        agegate:false
      },
      homeAvatar: "",
      showCreateInvite: false,
      showSettingsWindow: false,
      inviteList: null,
      slider: null,
      isDown: false,
      startX: null,
      scrollLeft: null,
      activities: Activities,
      simplemachinescards: simpleMachinesCards,
      drawingcards: drawingCards,
      simpleMachinesCard: {
        name: "simple-machines-card-1",
        heading: "Meet Rockie and Walkie. They help you slow down rolling planets. Time to rock n' roll",
        assetURL: "/img/cards/simple_machines_card_rocks.svg",
        routeName: "SimpleMachines"        
      },
      drawingCard: {
        name: "drawing-card-1",
        heading: "Draw with the hand you never write with.",
        assetURL: "/img/drawing/elements/inspirations/eyes.svg",
        routeName: "Drawing"        
      }      
    };
  },
  computed: {
    ...mapState(['activeProfile']),     
    ...mapState(['invites']), 
    ...mapState(['feedback']), 
    ...mapState(['currentRoom']),       
  },
  methods: {
    openPWA(){
      this.show.pwa = true;
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'download'
      }); 
    },
    closePwa(){
      this.show.pwa = false;
    },
    randomDrawcard() {
      let max = this.drawingcards.length;
      let n = Math.floor(Math.random() * Math.floor(max));
      this.drawingCard.name = this.drawingcards[n].hpTrackingName;
      this.drawingCard.heading = this.drawingcards[n].heading;
      this.drawingCard.assetUrl = this.drawingcards[n].assetUrl;
      this.drawingCard.routeName = this.drawingcards[n].routeName;
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'drawinginspiro:view',
        label: this.drawingcards[n].name
      });                 
    },
    randomSMcard() {
      let max = this.simplemachinescards.length;
      let n = Math.floor(Math.random() * Math.floor(max));
      this.simpleMachinesCard.name = this.simplemachinescards[n].name;
      this.simpleMachinesCard.heading = this.simplemachinescards[n].heading;
      this.simpleMachinesCard.assetUrl = this.simplemachinescards[n].assetUrl;
      this.simpleMachinesCard.routeName = this.simplemachinescards[n].routeName;
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'buildinginspiro:view',
        label: this.simplemachinescards[n].name
      });       
    },    
    async createSpaceForApp(app) {
      let space_id = this.currentRoom.id;
      if(!app){
        this.$router.push({ name: "Spaces", params: { space_id } }) 
      } else {        
        this.$store.commit('setActivity', app);
        this.$store.dispatch('updateActivity',app);
        this.$router.push({ name: "Spaces", params: { space_id } }) 
      }
    },
    openSpace(app,action) {
        if(app === 'SimpleMachines') {
          this.show.smipleMachine = false;
        } else {
          this.show.drawingApp = false;   
        }
        let trackAction = app + ":" +  action;
        this.tracking({
          type: 'event',
          category: 'homepage',
          action:  trackAction.toLowerCase() 
        });
        this.createSpaceForApp(app)
    },
    openFeedback() {
      let feedbackData = {
          show: true,
          playerName: "",
          sessionType: "",
          sessionRole: ""
      };
      this.$store.commit('setFeedback', feedbackData); 
    },  
    closeFeedback(){
      this.show.feedback = false;
      this.$store.commit('setFeedback', null);
    },
    openCreateInviteFromHome() {
      //Listens for the open create invite from the Nav compoenent
      this.showCreateInvite = true;       
    },
    closeCreateInviteFromHome() {
      this.showCreateInvite = false;      
    },   
    openSettingsWindowFromHome() {
      //Listens for the open settings from the Nav compoenent 
      this.showSettingsWindow = true;
    },
    closeSettingsWindowFromHome() {
      this.showSettingsWindow = false;      
    },
    scheduleCardStart(){
      this.show.scheduleCardStart = false;
      this.createSpaceForApp();
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'start',
      });  
    },
    inviteCard() {
      if(this.activeProfile.type === 'kid' ) { 
          this.show.agegate = true;
          //TODO open age gate
      } else {
          this.openCreateInviteFromHome();
      }
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'invite'
      });     
    },      
    correctPIN(){
      this.openCreateInviteFromHome();
      this.show.agegate = false;
    },      
    closeAgeGate() {
      this.show.agegate = false;
    },
    drawInspirationCard(name){
      this.show.drawInspirationCard = false;
      this.createSpaceForApp('Drawing');
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'drawinginspiro:click',
        label: name
      });       
    },
    smInspirationCard(name){
      console.log("card name: "+ name);
      this.show.smInspirationCard = false;
      this.createSpaceForApp('SimpleMachines');
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'buildinginspiro:click',
        label: name
      });       
    },
    savedCreationCard(){
      this.show.savedCreationCard = false;
      this.createSpaceForApp('savedCreation');
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'save:edit',
      });    
    },
    trackHomePage(){
      this.tracking({
        type: 'page',
        title: '/home',
      });
    }   
  },
  mounted () {
    this.randomDrawcard();
    this.randomSMcard();
    // This clear out beta invites and playdates when returning home.
    this.$store.commit('setSignUp', null);
    this.$store.commit('setInvite', null); 
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/_mixins.scss';
#home-screen {
  width: 1024px;
  height: 768px;
  /* width */
  ::-webkit-scrollbar {
      width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
      background: #161343;   
      box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: #4D2AB1;
      border-radius: 15px;
      //filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: $ps-purple;
  }



}
#home-content-container {
  width:100%;
  height:100%;
  position: absolute;
  overflow: hidden;
  box-sizing: content-box;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  img { 
      -webkit-user-drag: none; 
      -khtml-user-drag: none; 
      -moz-user-drag: none; 
      -o-user-drag: none; 
      user-drag: none; 
  }  
}
#home-content-slider {

  box-sizing: content-box;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;  
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;  
}
#home-content-drawer{
  position: relative;
  width: 1750px;
  //width: 2424px;
  height: 100%;
  max-height: 768px;
  min-height: 650px;
  box-sizing: content-box;
  white-space: nowrap;

  margin: 0;
  padding: 0;   
  background: url('/img/backgrounds/home/home_screen_room.svg');
  background-position: bottom left;
}
#home-content {
      padding: 120px 0 0 30px;
}
#home-avatar{
  width: 396px;
  height: 544px;
  img {
    margin: 100px 0 0 30px;
  }
}
.home-row {
  margin: 0 0 30px 0;
  flex-wrap: wrap;
  display: flex;
}
.p-2 {
  padding: 0 !important;
}
// other cards in global since they are used in more than one place: .
.tall-card {
  white-space: normal;
  position: relative;
  background: linear-gradient(180deg, #1A164E 0%, #5328B8 100%);
  width: 270px;
  height: 545px;
  margin: 0 30px 0 0;
  border-radius: 30px;
  padding: 30px;
  @include box-shadow(0px,5px,10px,0, rgba(0,0,0,0.25));
  .launch {
    margin: 30px 0 0 -15px;
  }
  .tall-card-heading {
    width: 200px;
    color: $ps-purple;
    white-space: normal;
    margin: 0 0 30px 0;
    h1 {
      color: $ps-white;
    }
  }
  p {
    color: $ps-white;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 0;

  }
  li {
    color: $ps-white;
    font-size: $p-size;
    line-height: $p-lh;
    margin: 0 0 20px 0;

  }
}
#schedule-friends {
  position: absolute;
  right: -10px;
  top: -25px;
  width: 130px;
  height: 182px;
}
.daily-challenge {
  position: absolute;
  left: 20px;
  top: 25px;
}
.card-copy-drawing {
  padding: 0 80px;  
}
.card-copy {
  padding: 0 30px;
}
</style>