<template>
    <div id="reminder-modal">
        <Smallmodal
        :modalShow="showPWA"
        @closeSmallModal="closeModal()"
        >
        <div id="pwa-modal-content">
            <h2>{{$t('pwa.heading')}}</h2>
            <br/>
            <p>{{$t('pwa.part1')}}</p>
            <br/>
            <p>{{$t('pwa.part2')}} <a href="https://www.playspaces.co/download-pwa" target="_blank">{{$t('pwa.link-text')}}</a> {{$t('pwa.part3')}}</p>
            <br/><br/>
            <b-button pill variant="primary" @click="installPWA()" v-if="showInstall == true">
                <div class="download-icon ps-icons"></div>
                {{$t('global.install')}}
            </b-button>          
            
        </div>
        </Smallmodal>
    </div>    
</template>

<script>
import { BeforeInstallPromptEvent } from "vue-pwa-install";
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'
export default {
    name: "Lostguest", 
    components: {
        Smallmodal
    },
    props:{
        showPWA: Boolean,
    },
    watch: {
        'showPlayerleft': {
            handler (newVal) {
                if (newVal && this.showPWA == true) { 
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'popup:view',
                        label: 'download-app'
                    });
                }
            },
            immediate: true 
        }
    },      
    computed: {
        ...mapState(['userData']), 
        
    },
    data: function() {
        return {
            error: null,
            showInstall: false,
            deferredPrompt: null,
            thisBrowser: "chrome"
        }   
    },
    methods: {
        closeModal() {
            this.$emit('closePwaModal');
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:close',
                label: 'download-app'
            });            
        },
        async installPWA(){
            // Show the prompt
            this.deferredPrompt.prompt();
            this.showInstall = true;

            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then(choiceResult => {
                if (choiceResult.outcome === "accepted") {
                console.log("PWA setup accepted");
                this.showInstall = true;
                } else {
                console.log("PWA setup rejected");
                }
                this.showInstall = false;
                this.deferredPrompt = null;
            });           
        }
    },
    created() {
      /*this.$on("canInstall", BeforeInstallPromptEvent () => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt:
        event.preventDefault();
        // Stash the event so it can be triggered later:
        this.deferredPrompt = event;
      });*/
    },
    mounted(){              
        window.addEventListener("beforeinstallprompt", e => {
            console.log("beforeinstallprompt fired");
            // Prevent Chrome 76 and earlier from automatically showing a prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            // Show the install button
            this.showInstall = false;
        });
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#pwa-modal-content {
    padding: 30px;
    text-align: left;
    h2 {
        text-align: center ;
    }

}

.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
}
</style>