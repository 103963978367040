<template>
    <div>
        <Reauth
        :oldpassword="oldpassword"
        :showReauthModal="showReauthModal"
        @reauthPassword="getReauthPassword($event)" 
        @closeSettingsReauthModal="closeReauthModal()"  
        />      
        <div id="primary-settings-group" class="settings-group" v-if="showUserData == true">
            <h2>{{$t('settings.youraccount')}}</h2>
            <div class="settings-edit-btn d-inline-block align-top">
            <a href="#" @click.prevent="openEditUserData()">{{$t('global.edit')}}</a>
            </div>
            <div v-if="error" class="error-msg">{{error}}</div>
            <br/>
            <div class="settings-field-label align-top">
                <label>{{$t('settings.firstname')}}</label>
            </div>
            <div class="settings-data-block-long align-top">
                {{userData.firstname}}
            </div>                 
            <br/>
            <div class="settings-field-label align-top">
                <label>{{$t('settings.lastname')}}</label>
            </div>
            <div class="settings-data-block-long align-top">
                {{userData.lastname}}
            </div>
            <br/>

            <div class="settings-field-label align-top">
                <label>{{$t('settings.youremail')}}</label>
            </div>
            <div class="settings-data-block-long align-top">
                {{userData.email}}
            </div>   
            <br/>     
            <div class="settings-field-label align-top">
                <label>{{$t('settings.password')}}</label>    
            </div> 
            <div class="settings-data-block-long align-top">
                **********
            </div>
        </div>  
        <div id="primary-settings-group" class="settings-group-purple" v-if="showEditUserdata == true">   
            <h2>{{$t('settings.edityouraccount')}}</h2>
            <div class="settings-edit-btn d-inline-block align-top">
              <a href="#" @click.prevent="closeEditUserData()">{{$t('global.cancel')}}</a>
              <a href="#" @click.prevent="validateUserDataEdit()">{{$t('global.save')}}</a>
            </div>
            <div v-if="error" class="error-msg">{{error}}</div>
            <br/>
            <form>
              <div class="settings-field-label">
                <label>{{$t('settings.firstname')}}</label>
              </div>
              <div class="settings-input-field-long">
                  <input
                  v-model.trim="userdataform.firstname"
                  id="firstname"
                  name="firstname"
                  maxlength=30
                  type="text"
                  class="form-control"
                  required
                  tabindex="2"
                  />
              </div>                 
              <br/>
              <div class="settings-field-label">
                <label>{{$t('settings.lastname')}}</label>
              </div>
              <div class="settings-input-field-long">
                  <input
                  v-model.trim="userdataform.lastname"
                  maxlength=100
                  id="lastname"
                  name="lastname"
                  type="text"
                  class="form-control"
                  required
                  tabindex="3"
                  />
              </div>
              <br/>
              <div class="settings-field-label">
                <label>{{$t('settings.youremail')}}</label>
              </div>
              <div class="settings-input-field-long">
                <input
                v-model.trim="userdataform.email"
                maxlength=320
                id="email"
                name="email"
                type="text"
                class="form-control"
                required
                tabindex="4"
                />
              </div>   
              <br/>     
              <div class="settings-field-label">
                <label>{{$t('settings.password')}}</label>
              </div> 
              <div class="settings-input-field-long">
                  <input
                  v-model.trim="userdataform.password"
                  maxlength=50
                  id="password"
                  name="password"
                  type="password"
                  class="form-control"
                  :placeholder="this.$t('settings.passwordplaceholder')"
                  required
                  tabindex="5"
                  />        
              </div>
            </form>
        </div>
    </div>           
</template>

<script>
import { mapState } from 'vuex'
import Reauth from '@/components/settings/reauth.vue'

export default {
  name: "Userdata",
  components: {
    Reauth
  },
  computed: {
    ...mapState(['userData'])
  },
  data () {
    return {
        showReauthModal: false,
        showUserData: true,
        showEditUserdata: false,
        error: null,
        updatePassword: false,
        oldpassword: null,
        userdataform: {
          firstname: null,
          lastname: null,
          email: null,
          password: null
        } 
    }
  }, 
  methods: {
    openReauthModal(){
      this.showReauthModal = true;
    },       
    closeReauthModal(){
      this.showReauthModal = false;
      this.closeEditUserData();
    },    
    getReauthPassword(password){
      this.oldpassword = password;
      this.updateUserData();
      this.closeReauthModal();
    },
    openEditUserData(){
      this.showUserData = false;
      this.showEditUserdata = true;
      this.userdataform.firstname = this.userData.firstname;
      this.userdataform.lastname = this.userData.lastname;
      this.userdataform.email = this.userData.email;
      this.tracking({
          type: 'event',
          category: 'settings',
          action: 'manage_account:edit',
      });        
    },
    closeEditUserData(){
      this.showUserData = true;
      this.showEditUserdata = false;
    },
    validateUserDataEdit() {
      if(!this.userdataform.firstname){
        this.error = this.$t('errors.firstname');
        return;
      }
      if(!this.userdataform.lastname){
        this.error = this.$t('errors.lasttname');
        return;
      }
      if(!this.userdataform.email){
        this.error = this.$t('errors.validemail');
        return;
      } else {
        if(!this.validEmail(this.userdataform.email)) {
          this.error = this.$t('errors.validemail');
          return;         
        }
      }
      if(this.userdataform.password !== null) {
        if(!this.validPassword(this.userdataform.password)) {
          this.error = this.$t('errors.validpassword');
          return;     
        } else {
          this.updatePassword = true; //They are changing the password.
        }        
      } else {
        this.updatePassword = false; //They aren't changing the password.
      }
      this.openReauthModal()
      this.tracking({
          type: 'event',
          category: 'settings',
          action: 'manage_account:save',
      });       
    },
    async updateUserData () {
      try {
           
          let result = await this.$store.dispatch('updateUser', {
            firstname: this.userdataform.firstname,
            lastname: this.userdataform.lastname,
            email: this.userdataform.email,
            oldpassword: this.oldpassword,
            newpassword: this.userdataform.password
          });
          

          if(result !== null){
            console.log(JSON.stringify(result));
            this.error = result.message;
          } else {
              this.closeEditUserData();                  
          }
      }
      catch (error) {
          this.error = error.message;
          this.logError(error);
      }  
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';

#primary-settings-group {
  max-width: 500px;
  min-width: 320px;
  //min-height: 250px;
  padding-right: 60px;
}

</style>
