<template>
<div id="parental-controls">
  <div v-if="showParentalControlsData == true">
    <div id="parental-controls-view" class="settings-group">
    <h2>{{$t('settings.managesettings')}}</h2>
    <div class="settings-edit-btn d-inline-block align-top">
      <a href="#" @click.prevent="openEditParentalControls()">{{$t('global.edit')}}</a>
    </div>
      <div v-if="error" class="error-msg">{{error}}</div>
      <br/> 
      <div class="parental-controls-field-label d-inline-block">
        <label>{{$t('settings.parentalcontrols')}}</label>
      </div>
      <div class="d-inline-block" v-if="this.userData.parentalPin != null">
        <div class="digit-block d-inline-block">*</div>
        <div class="digit-block d-inline-block">*</div>
        <div class="digit-block d-inline-block">*</div>
        <div class="digit-block d-inline-block">*</div>
      </div>
      <div class="d-inline-block" v-else>
        <div class="digit-block d-inline-block">&nbsp;</div>
        <div class="digit-block d-inline-block">&nbsp;</div>
        <div class="digit-block d-inline-block">&nbsp;</div>
        <div class="digit-block d-inline-block">&nbsp;</div>
      </div>
      <br/>
      <div class="parental-controls-field-label d-inline-block">
        <label>{{$t('settings.newsletter')}}</label>
      </div>
      <div class="parental-controls-data-block d-inline-block">
        <span v-if="this.userData.newsletter == true">On</span>
        <span v-else>Off</span>
      </div>
    </div>
  </div>  
  <div v-if="showEditParentalControls == true">
    <div id="parental-controls-edit"  class="settings-group-purple">    
      <h2>Edit Settings</h2>  
      <div class="settings-edit-btn d-inline-block align-top">
        <a href="#" @click.prevent="closeEditParentalControls()">{{$t('global.cancel')}}</a>
        <a href="#" @click.prevent="validateForm()">{{$t('global.save')}}</a>
      </div>      
      <div v-if="error" class="error-msg">{{error}}</div>
      <br/>
      <div class="parental-controls-field-label d-inline-block">
        <label>{{$t('settings.parentalcontrols')}}</label>
      </div>      
      <div id="parental-pin" class="d-inline-block">
        <input
        v-model.trim="pin.firstnumber" 
        class="digits"
        type="text" 
        maxlength=1
        id="firstnumber" 
        name="firstnumber"
        required
        autofocus
        tabindex="1"    
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
        />
        <input
        v-model.trim="pin.secondnumber" 
        class="digits"
        type="text" 
        maxlength=1
        id="secondnumber" 
        name="secondnumber"
        required
        autofocus
        tabindex="2"
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
        />
        <input
        v-model.trim="pin.thirdnumber" 
        class="digits"
        type="text" 
        maxlength=1
        id="thirdnumber" 
        name="thirdnumber"
        required
        autofocus
        tabindex="3"
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
        /> 
        <input
        v-model.trim="pin.forthnumber" 
        class="digits"
        type="text" 
        maxlength=1
        id="forthnumber" 
        name="forthnumber"
        required
        autofocus
        tabindex="4"
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
        />         
      </div>
      <br/><br/>
      <div class="parental-controls-field-label d-inline-block">
        <label>{{$t('settings.newsletter')}}</label>
      </div>
      <div class="newsletter-input d-inline-block">
      <b-form-checkbox 
      v-model.trim="newsletter"
      id="newsletter"
      name="newsletter"
      type="checkbox"
      tabindex="7"
      switch>
      </b-form-checkbox>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "ParentalControls",
  props: {
      
  },  
  computed: {
    ...mapState(['userData'])
  },    
  data() {
    return {
      showParentalControlsData: true,
      showEditParentalControls: false,
      pin: {
        firstnumber: null,
        secondnumber: null,
        thirdnumber: null,
        forthnumber: null
      },
      combinedPIN: null,
      newsletter: null,
      error: null
    }
  },
  methods: {
    openEditParentalControls(){
      this.showParentalControlsData = false;
      this.showEditParentalControls = true;
      if(this.userData.parentalPin) {
        //PIN is the only optional field so check if it exists.
        let p = this.userData.parentalPin.toString().split('');
        this.pin.firstnumber = p[0];
        this.pin.secondnumber = p[1];
        this.pin.thirdnumber = p[2]; 
        this.pin.forthnumber = p[3];
      }
      this.newsletter = this.userData.newsletter;   
      this.tracking({
          type: 'event',
          category: 'settings',
          action: 'manage_settings:edit',
      });             
    },
    closeEditParentalControls(){
      this.showParentalControlsData = true;
      this.showEditParentalControls = false;
      this.error = null;
    },    
    validateForm(){
      //PIN is optional, check if there's any input and valid if it's blank ignore it.
      if(this.pin.firstnumber != null || this.pin.secondnumber != null || this.pin.thirdnumber != null || this.pin.forthnumber != null ) {
        this.combinedPIN = this.pin.firstnumber + this.pin.secondnumber + this.pin.thirdnumber + this.pin.forthnumber;
        if(this.validPIN(parseInt(this.combinedPIN)) === false) {
          this.error = this.$t('errors.pinformat');
          return;
        }
      }
      this.updateParentalControls();
      this.tracking({
          type: 'event',
          category: 'settings',
          action: 'manage_settings:save',
      });        
    },
    updateParentalControls(){
      this.$store.dispatch('updateParentalCtls', {
        parentalPin: this.combinedPIN,
        newsletter: this.newsletter,
      })
      .catch(error => {
        this.error = error.message;
        this.logError(error);
      })
      //TODO have this modify newsletter setting in mailchimp
      this.closeEditParentalControls();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#parental-controls, #parental-controls-view, #parental-controls-edit {
  min-height: 194px;
  .parental-controls-field-label  {
    width: 210px;
    height: 30px;
  }
  .parental-controls-data-block{
    border-bottom: $ps-light-purple solid 1px;
    padding: 8px;
    width: 80px;

  }
  .digit-block {
    width: 31px;
    border-bottom: $ps-light-purple solid 1px;
    margin: 0 15px 0 0 ;
    padding: 8px 10px;
    font-size: $h2-size;
  }  
}

</style>