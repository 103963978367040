<template>
<div>
  <AgeGate
  :showAgeGate="showAgeGate" 
  :nextAction="nextAction"  
  @correctPIN="pinAccepted($event)" 
  @closeAgeGate="closeAgeGate()"
  />
  <div id="main-nav">
    <nav class="navbar ">
      <div class="d-inline-block ">
        <router-link to="home" class="logo">{{$t('global.app_name')}}</router-link>
      </div>
      <div class="nav-info d-inline-block">  
          <div >
            <div class="nav-welcome d-inline-block">
              {{$t('nav.greeting')}}{{mainProfileName}} 
              <span @click="showNavBar()" class="pointer menu-button">{{$t('nav.menu')}}</span>
            </div>
            <div @click="showNavBar()" class="pointer nav-avatar d-inline-block">
              <div class="avatar-container avatar-sm avatar-selected-sm" >
                <img class="avatar-img" :src="mainProfilePhoto" width="50" height="50" />
              </div>
            </div>
          </div>
      </div>
    </nav>
    <div id="nav-dropdown-container" v-if="showNavMenu == true" @click="hideNavBar()">
      <div id="nav-dropdown-menu">
        <ul>
          <li v-if="multiProfile == true">
            <div id="switch-profile">
             {{$t('nav.switchprofile')}} <div class="lock-icon-purple ps-icons-nav"></div>
              <br/><br/>
              <div align-h="start">
                <div class="d-inline-block" v-for="profile in profileList" :key="profile.id" @click="openAgeGate('navSwitchProfile',profile)">
                  <div class="profile-item" v-if="profile.id == activeProfile.id">
                    <div class="avatar-container avatar-sm avatar-selected-sm">
                      <img class="avatar-img" :src="profile.profilePhoto" width="50" height="50"/>
                    </div>
                    <div class="profile-name truncate">{{profile.name}}</div>
                  </div> 
                  <div class="profile-item" v-else>
                    <div class="avatar-container avatar-sm avatar">
                      <img class="avatar-img" :src="profile.profilePhoto" width="50" height="50"/>
                    </div>
                    <div class="profile-name truncate">{{profile.name}}</div>
                  </div>                 
                </div>
              </div>
            </div>
          </li>
          <li>
            <a @click.prevent="openAgeGate('schedulePlaydate', null)">{{$t('nav.scheduleplaydate')}} <div class="lock-icon-purple ps-icons-nav"></div></a>
          </li>
          <li>
            <a @click.prevent="openAgeGate('openSettingsWindow', null)">{{$t('nav.managesettings')}} <div class="lock-icon-purple ps-icons-nav"></div></a>
          </li>
          <li>
            <a @click.prevent="sendtoFAQ()">{{$t('nav.faq')}} <div class="linkout-icon-purple ps-icons-nav"></div></a>
          </li>   
          <li>
            <a @click.prevent="supportEmail()">{{$t('nav.support')}} <div class="linkout-icon-purple ps-icons-nav"></div></a>
          </li>                  
        </ul>
      </div>
    </div>  
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import AgeGate from '@/components/modals/agegate.vue'
export default {
  name: "navigation",  
  components: {
        AgeGate
  },   
  props: {
    openAgeGateHome: Boolean
  },
  data () {
    return {
      showAgeGate: false,
      showNavMenu: false,
      multiProfile: true,
      nextAction: null,
      nextProfile: null,
      profileList: null,
      profileCount: 0,
      mainProfilePhoto: "/img/avatars/unset_avatar.svg",
      mainProfileName: "username"
    }
  },     
  watch: {
    'openAgeGateHome': {
      handler (newVal) {
        if (newVal) { 
          if(this.openAgeGateHome === true) {
            this.showAgeGate = true;
            this.nextAction = 'createInviteHome';
          }
        }
      },
      immediate: true 
    },    
    'activeProfile': {
      handler (newVal) {
        if (newVal) { 
          this.mainProfilePhoto = this.activeProfile.profilePhoto;
          this.mainProfileName = this.activeProfile.name;
        }
      },
      immediate: true 
    },
    'profiles': {
      handler (newVal) {
        if (newVal) { 
          this.profileList = this.$store.getters.profileList;
          this.profileCount = this.$store.getters.profileCount;
          if(this.profiles.count == 0) {

             this.noProfilesLoaded();
          }
          /*if(this.profileCount < 2){
            this.multiProfile = false;
          } */ 
        }
      },
      immediate: true 
    },
    'currentRoom': {
      handler (newVal) {
        if (this.currentRoom.userId !== this.activeProfile.id) { 
          this.switchProfile(this.activeProfile);

        }
      },
      immediate: true 
    }     
  },  
  computed: {
    ...mapState(['userData']),   
    ...mapState(['profiles']),      
    ...mapState(['activeProfile']),    
    ...mapState(['currentRoom']),   
  },
  mounted () {
    this.profileList = this.$store.getters.profileList;
    this.profileCount = this.$store.getters.profileCount;    
    
  },
  methods: {
    noProfilesLoaded() {
      console.log("no profiles")
      this.$router.push('/signup/step2'); 
    },
    showNavBar(){
      if(this.showNavMenu == false){
        this.showNavMenu = true;
      } else {
        this.showNavMenu = false;
      }
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'nav:profile',
      });  
    },
    supportEmail(){
      let url;
      url = 'mailto:support@playspaces.co';
      url += '?subject=Playspaces Beta Help';      
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'nav:support',
      }); 
      window.open(url,'_blank');  
    },
    sendtoFAQ(){
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'nav:faq',
      }); 
      window.open('https://www.playspaces.co/faq','_blank');  
    },
    pinAccepted(actionName) {
      this.showAgeGate = false;
      if(actionName === "schedulePlaydate"){
        this.schedulePlaydate();
      } else if (actionName === "openSettingsWindow") {
        this.openSettingsWindow();
      } else if (actionName === "navSwitchProfile") {
        this.navSwitchProfile(this.nextProfile);
      } else if (actionName === "createInviteHome") {
        this.$emit('pinAccepted');
      }
    },
    closeAgeGate(){
      this.showAgeGate = false;
    },
    openAgeGate(actionName, profile){
      if(profile != null) {
        this.nextProfile = profile;
      }      
      if(this.activeProfile.type === 'kid' ) { //&& this.userData.parentalPin !== null
        this.nextAction = actionName;
        this.showAgeGate = true;    
      } else {
        this.pinAccepted(actionName);
      }       
    },
    openSettingsWindow() {
      this.$emit('openSettingsWindow');
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'nav:managesettings',
      });        
    },
    schedulePlaydate() {
      this.$emit('openCreateInvite');
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'nav:schedule',
      }); 
    },  
    hideNavBar(){
      this.showNavMenu = false;
    },    
    async navSwitchProfile(profile){  
      this.tracking({
        type: 'event',
        category: 'homepage',
        action:  'nav:change',
      }); 
      this.switchProfile(profile)           
      this.showNavMenu = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
.menu-button {
  margin: 0 0 0 50px;
}
.navbar {
  position: absolute;
  max-width: 1024px;
  width: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  padding: 30px 15px 0 15px;
}
.logo  {
  font-family: 'Lexend Zetta', sans-serif;
  color: $ps-white;
  font-size: 2.25rem;//36px;  
  font-weight: 400;
  text-decoration: none;
}
.nav-welcome {
  vertical-align:top;
  color: $ps-white;
  font-size: 14px;
  min-width: 125px;
  max-width: 250px;
  height: 50px;
  margin: 20px 15px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}
.nav-avatar {
  vertical-align:top;
  width: 50px;
  height: 50px;  
}
.nav-info {
  vertical-align: middle;
  max-width: 325px;  
  height: 50px;

  vertical-align:top;
}
#nav-dropdown-container{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1100;
}
#nav-dropdown-menu {
  position: absolute;
  top: 90px;
  right: 20px;
  width: 306px;
  padding: 15px 30px;
  border-radius: 30px;
  background: $ps-light-purple;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
  #switch-profile {
    font-size: 14px;
  }
  .profile-name {
    font-size: 10px;
    text-align: center;
  }
  .truncate {
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }  
  .profile-item {
    text-align: center;
    width: 50px;
    margin: 0 10px 10px 0;
  }
  ul {
    list-style-type: none; 
    padding: 0;
    margin: 0; 
  }
  li {
    font-size: 14px;
    font-weight: 600;
    a{
      font-size: 14px;
    }
    margin: 10px 0 15px 0;
  }
}
#nav-dropdown-menu::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 74%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent $ps-light-purple transparent;  
}

</style> 
