<template>
<div id="settings-avatars-modal-overlay" @click="closeSettingsAvatarModal()" v-if="showAvatarsModal == true">
    <div id="settings-avatars-modal-container" >
        <div id="settings-avatars-modal" class="ps-modal-box">
            <div >
                <div id="avatar-list">
                    <div v-for="avatar in avatars" :key="avatar.id" class="avatar-col">
                        <div @click="setAvatar(avatar.asset)" class="avatar-container avatar-sm avatar">
                        <img v-bind:id="avatar.name" class="avatar-img" v-bind:src="avatar.asset" width="40" height="40" />
                        </div>
                    </div>
                </div>
            </div>  
        </div>         
    </div>
</div>    
</template>
<script>
import Avatars from '@/data/avatars.json'
export default {
  name: "Avatars", 
  props: {
    avatarPhoto: String,
    showAvatarsModal: Boolean
  },
  data() {
    return {
      avatars: Avatars,
      selectedAvatar: ''
    };
  },
  methods: {
     setAvatar(path) {
      this.$emit('changeAvatar',path);
    },
    closeSettingsAvatarModal(){
      this.$emit('closeSettingsAvatarModal');        
    }     
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';
#settings-avatars-modal-overlay {
    //TODO fix this as it's not the full window
    position: absolute;
    top:0px;
    left: 0px;
    width: 1024px;
    height: 768px;
    //background: #FFF;
    z-index: 5400;    
}
#settings-avatars-modal-container {
    position: absolute;
    top: -23px;
    left: 160px;
}
#settings-avatars-modal::after {
  top: 40%;
  left: 0px;
  border: solid transparent;
  transform: rotate(90deg);
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: $ps-light-purple;
  border-width: 18px;
  margin-left: -36px;
}
#settings-avatars-modal::before {
  content: '';
  top: 40%;
  left: 0px;  
  position: absolute;
  transform: rotate(45deg);
  width: 36px;
  height: 36px;
  bottom: -12px;
  z-index: -1;
  margin-left: -11px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
}
#settings-avatars-modal {
    background: $ps-light-purple;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    width: 340px;
    height: 100%;
    padding: 30px;
    text-align: center;
}
#avatar-list {
  width: 100%; 
  margin: 0 auto; 
  .avatar-col {
    display: inline-block;
    margin: 0 8px;
    cursor: pointer;
    
  }
}
</style>